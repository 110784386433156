<template>
    <div ref="tier" class="content-index-tier">
        <div v-if="title && !hideEmptyResultsTitle && docs.length > 0" class="heading">
            <div class="heading-wrapper">
                <Typography as="h2" variant="h3-display" class="title">{{ title }}</Typography>
            </div>
        </div>

        <div class="panel" ref="panel">
            <div class="panel-inner">
                <div v-if="hasSearch" class="search">
                    <form @submit.prevent="onSubmit" class="search-input">
                        <TextInput
                            v-model:inputValue.trim="searchString"
                            :isClearable="showClearIcon"
                            icon="search"
                            ariaLabel="Search"
                            size="large"
                            :onSubmitClicked="onSubmit"
                            :onClearClicked="() => (payload.textSearch = '')"
                        />

                        <button v-if="!isSearched" class="submit-button">
                            <Icon name="arrow-submit" @click="onSubmit" />
                        </button>
                    </form>

                    <div class="filter-toggler">
                        <Button
                            @click="onFilterToggle"
                            variant="secondary"
                            iconPosition="left"
                            :deactivated="!isFiltersToggled"
                        >
                            <template #icon>
                                <Icon name="filters" />
                            </template>

                            {{ $t('filters') }}
                        </Button>
                    </div>
                </div>

                <IndexFilters
                    v-if="hasFilters && docs.length > 0"
                    v-show="isFiltersToggled"
                    @change="onFilterChange"
                    :title="title"
                    class="index-filters"
                    :renderedFilters="renderedFilters"
                    :defaultFilters="defaultFilters"
                    :hasSearch="hasSearch"
                    :pageLanguage="pageLanguage"
                />
            </div>
        </div>

        <div class="results">
            <div class="results-inner">
                <Typography v-if="!hideResultCount && docs.length > 0" variant="label-small" class="result-count"
                    >{{ totalDocs }} {{ $t('Results', 1, { locale: pageLanguage }) }}
                </Typography>

                <template v-if="$slots.results">
                    <slot name="results" :results="docs" :nextPage="nextPage"></slot>
                </template>

                <template v-else>
                    <div class="desktop-results">
                        <ContentCardBlock
                            v-for="doc in docs"
                            :key="doc.id"
                            :related="doc"
                            orientation="wide"
                            size="100"
                            :isProportionate="true"
                            :showPublicationDate="true"
                        />
                    </div>
                    <div class="mobile-results">
                        <ContentCardBlock
                            v-for="doc in docs"
                            :key="doc.id"
                            :related="doc"
                            orientation="wide"
                            size="50"
                            :showPublicationDate="true"
                        />
                    </div>
                </template>
            </div>
        </div>

        <div v-if="hasPagination" class="pagination">
            <Pagination
                @next="onNext"
                @previous="onPrevious"
                @page-select="onPageSelect"
                :nextPage="nextPage"
                :prevPage="prevPage"
                :totalDocs="totalDocs"
                :totalPages="totalPages"
            >
            </Pagination>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    contentType: {
        type: String,
        default: '',
    },
    limit: {
        type: Number,
        default: 10,
    },
    pageId: {
        type: String,
        default: '',
    },
    hasPagination: {
        type: Boolean,
        default: true,
    },
    hasSearch: {
        type: Boolean,
        default: false,
    },
    hasFilters: {
        type: Boolean,
        default: true,
    },
    renderedFilters: {
        type: Array,
    },
    defaultFilters: {
        type: Object,
    },
    hideResultCount: {
        type: Boolean,
        default: false,
    },
    textSearch: {
        type: String,
        default: '',
    },
    hideEmptyResultsTitle: {
        type: Boolean,
        default: false,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});
const emit = defineEmits(['onFilterChanged']);

const tier = ref(null);
const panel = ref(null);
const isSearched = ref(false);

onMounted(() => {
    const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
        { threshold: [1] },
    );

    observer.observe(panel.value);
});

const payload = reactive({
    collection: props.contentType,
    filters: props.defaultFilters,
    id: props.pageId,
    page: 1,
    limit: props.limit,
    textSearch: props.textSearch,
});

function onNext() {
    updatePage(payload.page + 1);
}

function onPrevious() {
    updatePage(payload.page - 1);
}

function onPageSelect(pageNumber) {
    updatePage(pageNumber);
}

function updatePage(pageNumber) {
    payload.page = pageNumber;
    tier.value.scrollIntoView();
}

const { docs, nextPage, prevPage, totalDocs, totalPages } = await useContentIndexData(payload);

function onFilterChange(filterPayload) {
    payload.page = 1;
    payload.filters = filterPayload;
    emit('onFilterChanged', filterPayload);
}

const searchString = ref('');

const isFiltersToggled = ref(true);

function onFilterToggle() {
    isFiltersToggled.value = !isFiltersToggled.value;
}

function onSubmit() {
    payload.textSearch = searchString.value;
    isSearched.value = true;
}

const showClearIcon = computed(() => {
    return !!isSearched.value && !!searchString.value;
});
</script>

<style lang="scss" scoped>
.content-index-tier {
    padding-bottom: 0.9rem;
    border-bottom: 1px solid palette(divider);
    background-color: color(blanc);
}

.heading {
    padding-top: vertical-space(6);
    background-color: color(grey, light);
    @include hide-on(phone);
}

.heading-wrapper {
    @include content-section;
}

.title {
    padding-top: vertical-space(1);
    padding-bottom: vertical-space(1);
    @include content-area;
}

.panel {
    @include content-section;

    @include media-query(phone) {
        @include z-index(header);
        position: sticky;
        top: -1px;
        background-color: color(blanc);

        &.is-pinned {
            box-shadow: 0 0.2rem 1rem 0 #0000001a;
        }
    }
}

.panel-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    @include content-area;

    @include media-query(phone-mw) {
        display: block;
    }
}

.search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 2.8rem;
    flex-grow: 1;

    @include media-query(phone-mw) {
        margin-bottom: 2.4rem;
    }
}

.search-input {
    flex: 1 1 auto;
    position: relative;
}

.submit-button {
    position: absolute;
    inset-inline-end: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    fill: color(blue);
    width: 2.5rem;
}

.index-filters {
    padding: 0.9rem 0;

    @include media-query(phone) {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
}

.filter-toggler {
    flex: 0 0 auto;
    display: none;

    @include media-query(phone-mw) {
        display: block;
    }
}

.results {
    @include content-section;
    margin-block: 5rem;
}

.results-inner {
    padding-top: 5rem;
    @include content-area;
}

.result-count {
    opacity: 0.65;
}

.desktop-results {
    display: none;

    @include media-query(phone-mw) {
        display: block;
    }
}

.mobile-results {
    @include media-query(phone-mw) {
        display: none;
    }
}
</style>
