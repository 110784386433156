<template>
    <FormField :class="CSSClasses">
        <label v-if="label" :for="input?.id">
            {{ label }}
        </label>

        <div class="input-container">
            <slot name="submitButton">
                <button class="submit-icon-button" @click="(e) => handleSubmitClicked(e)">
                    <Icon v-if="icon" :name="icon" class="input-icon" />
                </button>
            </slot>
            <input
                :value="inputValue"
                @input="$emit('update:inputValue', $event.target.value)"
                type="text"
                class="text-input"
                :aria-label="ariaLabel"
                v-uid
                ref="input"
                :placeholder="placeholder"
            />

            <button v-if="isClearable" class="cancel-button">
                <Icon name="cancel" @click="(e) => handleClearClicked(e)" />
            </button>
        </div>
    </FormField>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        default: '',
    },
    inputValue: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    variant: {
        type: String,
        default: 'default',
        validator: (value) => {
            return ['default', 'large'].includes(value);
        },
    },
    buttonPosition: {
        type: String,
        default: 'default',
        validator: (value) => {
            return ['default', 'reversed'].includes(value);
        },
    },
    isClearable: {
        type: Boolean,
        default: false,
    },
    ariaLabel: {
        type: String,
        default: '',
    },
    onSubmitClicked: {
        type: Function,
    },
    onClearClicked: {
        type: Function,
        default: () => undefined,
    },
});

const input = ref();
const emit = defineEmits(['update:inputValue']);

const handleSubmitClicked = (e) => {
    e.preventDefault();
    props.onSubmitClicked();
};
const handleClearClicked = (e) => {
    e.preventDefault();
    emit('update:inputValue', '');
    if (props.onClearClicked) {
        props.onClearClicked();
    }
};
const CSSClasses = computed(() => {
    return [
        `variant-${props.variant}`,
        `button-position-${props.buttonPosition}`,
        props.icon && 'is-indented',
        props.isClearable && 'is-clearable',
    ];
});
</script>

<style lang="scss" scoped>
.text-input {
    width: 100%;
    background-color: color(grey);
    padding-top: 1.6rem;
    padding-bottom: 1.4rem;
    padding-inline-end: 1.6rem;
    padding-inline-start: 1.4rem;
    border-radius: 2px;
    @include type-h5-display;

    .is-indented & {
        padding-inline-start: 5rem;
    }

    .is-clearable & {
        padding-inline-end: 5rem;
    }

    .variant-large & {
        font-size: 1.4rem;

        &::placeholder {
            font-style: italic;
        }
    }

    & {
        font-weight: 300;
    }
}

.input-container {
    position: relative;
    display: flex;

    .button-position-reversed & {
        flex-direction: row-reverse;
    }
}

.input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: color(offnoir);
    opacity: 0.3;

    .button-position-reversed & {
        inset-inline-end: 1.6rem;
    }

    .button-position-default & {
        inset-inline-start: 1.6rem;
    }
}

.cancel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: color(blue);

    .button-position-reversed & {
        inset-inline-start: 1.6rem;
    }

    .button-position-default & {
        inset-inline-end: 1.6rem;
    }
}

.submit-icon-button {
    cursor: pointer;
}

.variant-large {
    padding: 0.8rem;
    background-color: color(grey);
    border-radius: 0.4rem;
}
</style>
